import img1 from './../../images/svg-2.svg';
import certificate from './../../images/certificate.png';
import contractor from './../../images/contractor.png';
import contractor2 from './../../images/contractor2.png';

export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'About',
  headline: 'About',
  description: 'Get access to our exclusive services',
  buttonLabel: 'Get started',
  imgStart: false,
  img: img1,
  alt: 'car',
  dark: true,
  primary: true,
  darkText: true,
  certificate,
  contractor,
  contractor2,
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Unlimited Access',
  headline: 'Login to your account at any time',
  description:
    'Get access to our exclusive app that allows you to send unlimited transactions whitout getting charged any fees.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: './../../images/svg-4.svg',
  alt: 'Piggybanck',
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Premium Bank',
  headline: 'Creating an account is extremely easy',
  description:
    'Get access to our exclusive app that allows you to send unlimited transactions whitout getting charged any fees.',
  buttonLabel: 'Start Now',
  imgStart: false,
  img: './../../images/svg-3.svg',
  alt: 'car',
  primary: false,
  darkText: true,
};
