import styled from 'styled-components';

export const ServiceContainer = styled.div`
  background: #eaedef;
  box-sizing: border-box;
`;
export const SHead = styled.h2`
  text-align: center;
  color: #252525;
  background: #eaedef;
  padding: 20px;
  max-width: 1280px;
  margin: auto;
`;
export const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background: #eaedef;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1280px;
  margin: auto;
  padding: 20px 48px;
`;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 150px;
  background: #92ffd2;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid #2b2b2b;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(1.02);
  }
`;
export const ServiceHeader = styled.h3`
  margin-bottom: 20px;
  color: #252525;
`;

export const ServiceText = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: #252525;
`;
export const ServiceLink = styled.a`
  font-size: 16px;
  color: #252525;
  &:hover {
    font-weight: bold;
  }
`;
