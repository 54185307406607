import styled from "styled-components";
import ceo from "./../../images/ceo.jpg";

export const MsgContainer = styled.div`
  display: flex;
  max-width: 1280px;
  margin: auto;
  display: flex;
  align-content: center;
  justify-content: space-between;
  background-color: #009dd6;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  @media screen and (min-width: 768px) {
    height: 500px;
  }

  @media screen and (min-width: 769px) {
    height: 400px;
  }
`;
export const MsgCard = styled.div`
  padding: 30px;
  border-radius: 20px;
  color: white;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`;
export const CeoImageContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 300px;
  flex-grow: 1;

  @media screen and (max-width: 768px) {
    width: 300px;
    height: 300px;
    padding-top: 20px;
    justify-content: center;
  }
`;

export const CeoImage = styled.img.attrs(() => ({
  src: ceo,
}))`
  width: 350px;
  display: block;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 300px;
    height: 300px;
  }

  @media screen and (max-width: 500px) {
    width: 270px;
  }
`;

export const MsgHeader = styled.h2`
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 23px;
  }
`;
export const MsgParagraph = styled.p`
  padding-top: 20px;
`;
export const MsgWrapper = styled.div`
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 30px;
  margin: auto;
  text-align: justify;

  @media screen and (max-width: 768px) {
    order: 2;
    width: 90%;
    padding: 20px 10px;
  }
`;
