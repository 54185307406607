import styled from 'styled-components';

export const ContactContainer = styled.div`
  background: #eaedef;
  padding: 0 30px;
  margin-top: 20px;
`;

export const ContactWrapper = styled.div`
  background: #eaedef;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  margin: auto;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media screen and (min-width: 950px) {
    width: 100%;
  }
`;

export const ContactHeader = styled.h2`
  text-align: center;
  color: #252525;
  padding: 20px;
  max-width: 1280px;
  margin: auto;
`;

export const ContactInfo = styled.div`
  line-height: 25px;
  margin-bottom: 20px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 18px;

  @media screen and (max-width: 1000px) {
    width: 50%;
  }
`;

export const ContactForm = styled.div`
  border-radius: 5px;
  background-color: #eaedef;
  padding: 20px;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;
