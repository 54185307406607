import React from 'react';
import {
  Service,
  ServiceContainer,
  ServiceHeader,
  ServiceLink,
  ServiceText,
  ServiceWrapper,
  SHead,
} from './ServicesElements';

const Services = () => {
  return (
    <>
      <ServiceContainer id="services">
        <SHead>Services We Offer</SHead>

        <ServiceWrapper>
          <Service>
            <ServiceHeader>
              Supply and installation of Electrical Sub - Station Equipment
              (Distribution)
            </ServiceHeader>
            {/* <ServiceText>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Dignissimos suscipit
            </ServiceText>
            <ServiceLink href="#">View Services</ServiceLink> */}
          </Service>

          <Service>
            <ServiceHeader>
              33KV & 11KV Overhead Line & Underground Line Construction
            </ServiceHeader>
            {/* <ServiceText>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Dignissimos suscipit
            </ServiceText>
            <ServiceLink href="#">View Services</ServiceLink> */}
          </Service>
          <Service>
            <ServiceHeader>
              Transformer, HT Switchgear, Main LT Switchgear & PFI Plant.
            </ServiceHeader>
            {/* <ServiceText>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Dignissimos suscipit
            </ServiceText>
            <ServiceLink href="#">View Services</ServiceLink> */}
          </Service>
          <Service>
            <ServiceHeader>Civil Works</ServiceHeader>
            {/* <ServiceText>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Dignissimos suscipit
            </ServiceText>
            <ServiceLink href="#">View Services</ServiceLink> */}
          </Service>
          <Service>
            <ServiceHeader>
              Busbar Trunking System (Both Copper & Aluminum)
            </ServiceHeader>
          </Service>

          <Service>
            <ServiceHeader>All kinds of Light Fittings</ServiceHeader>
          </Service>

          <Service>
            <ServiceHeader>All Kinds of Cable Tray</ServiceHeader>
          </Service>

          <Service>
            <ServiceHeader>Automatic Voltage Stabilizer</ServiceHeader>
          </Service>

          <Service>
            <ServiceHeader>
              Industrial Electrical Design & Planning
            </ServiceHeader>
          </Service>

          <Service>
            <ServiceHeader>
              Installation Works & all Kinds of Power Cable Laying
            </ServiceHeader>
          </Service>

          <Service>
            <ServiceHeader>
              Installation of Thundering Protection & Earthing System
            </ServiceHeader>
          </Service>
        </ServiceWrapper>
      </ServiceContainer>
    </>
  );
};

export default Services;
