import React, { useState } from 'react';
import Clients from '../components/Clients/Clients';
import Contact from '../components/contact/Contact';
import Footer from '../components/Footer/Footer';
import Gallery from '../components/Gallery/Gallery';
import HeroSection from '../components/Hero/Hero';
import { homeObjOne } from '../components/Info/Data';
import Info from '../components/Info/Info';
import MsgCeo from '../components/MsgCeo/MsgCeo';
import Navbar from '../components/Navbar/Navbar';
import Services from '../components/Services/Services';
import Sidebar from '../components/Sidebar/Sidebar';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <Info {...homeObjOne} />
      {/* <Info {...homeObjTwo}/> */}
      <MsgCeo />
      <Services />

      <Clients />
      <Gallery/>
      <Contact />

      <Footer />
    </>
  );
};

export default Home;
