import styled from 'styled-components';
 
export const Container=styled.div`
   background: #f2f4f6;
   box-sizing: border-box;
`
export const GalleryWrapper= styled.div `
   padding: 20px 48px; 
   max-width: 1280px;
   margin: auto;
`

export const GalleryContainer =styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1280px;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
 

  @media only screen and (max-width: 700px) {
    
}

@media only screen and (max-width: 500px) {
    width: 100%;
}
    
`

export const GalleryHeader= styled.h2 `
 text-align: center;
 color: #252525;
 padding: 20px;
 max-width: 1280px;
 margin: auto;
    
`

export const GalleryImage= styled.div `

`

export const Image = styled.img`
 width: 300px;
 height: 200px;
 border-radius: 10px;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

