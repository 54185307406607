import React from 'react';
import {
  CeoImage,
  CeoImageContainer,
  MsgCard,
  MsgContainer,
  MsgHeader,
  MsgParagraph,
  MsgWrapper,
} from './MsgCeoElements';

const MsgCeo = () => {
  return (
    <>
      <MsgContainer>
        <MsgWrapper>
          <MsgCard>
            <MsgHeader>Message From Our MD</MsgHeader>
            <MsgParagraph>
              We are facing a major turning point in the global economy and
              society that will change the way we all do business. Conventional
              thinking and traditional operating models will not be able to
              capture the opportunities that arise from this paradigm shift. By
              taking the initiative to transform ourselves, we aim to deliver
              added value to our clients and all stakeholders.
            </MsgParagraph>
          </MsgCard>
        </MsgWrapper>
        <CeoImageContainer>
          <CeoImage />
        </CeoImageContainer>
      </MsgContainer>
    </>
  );
};

export default MsgCeo;
