import styled from 'styled-components';

export const Container = styled.div`
  background: #f2f4f6;
  box-sizing: border-box;
  margin-top: 20px;
`;
export const ClientHead = styled.h2`
  text-align: center;
  color: #252525;
  padding: 20px;
  max-width: 1280px;
  margin: auto;
`;
export const CLientWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1280px;
  margin: auto;
  padding: 20px 48px;
`;

export const Client = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 80px;
  padding: 10px;
  background: white;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &:hover {
    transform: scale(1.02);
  }
`;
export const ClientName = styled.h3`
  text-align: center;
  font-size: 0.8rem;
  color: #000000;
`;

// export const ServiceText =styled.p`
// margin-bottom: 20px;
// font-size: 14px;
// color: #252525;

// `
// export const ServiceLink =styled.a`
// font-size: 16px;
// color: #252525;
// &:hover{
//     font-weight: bold;
// }
// `
