import React from 'react'
import gallery1 from './../../images/gallery1.png'
import gallery2 from './../../images/gallery2.png'
import gallery3 from './../../images/gallery3.png'
import gallery4 from './../../images/gallery4.png'
import gallery5 from './../../images/gallery5.png'
import gallery6 from './../../images/gallery6.png'
import { Container, GalleryContainer, GalleryHeader, GalleryImage, GalleryWrapper, Image } from './GalleryElements'


const Gallery = () => {

  return (

    <Container>
      <GalleryWrapper>
      <GalleryHeader>
        Our Recent Works
      </GalleryHeader>
      <GalleryContainer>
        <GalleryImage>
        <Image src={gallery1}alt="Gallery"/>
        </GalleryImage>

        <GalleryImage>
        <Image src={gallery2}alt="Gallery"/>
        </GalleryImage>
        <GalleryImage>
        <Image src={gallery3}alt="Gallery"/>
        </GalleryImage>
        <GalleryImage>
        <Image src={gallery4} alt="Gallery"/>
        </GalleryImage>
        <GalleryImage>
        <Image src={gallery5} alt="Gallery"/>
        </GalleryImage>
        <GalleryImage>
        <Image src={gallery6} alt="Gallery"/>
        </GalleryImage>
      </GalleryContainer>
    </GalleryWrapper>
    </Container>
    
  )
}

export default Gallery