import styled from "styled-components";

export const InfoHeader = styled.h2`
  text-align: center;
  color: #252525;
  padding: 20px;
  max-width: 1280px;
  margin: auto;
`;

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};

  @media screen and (max-width: 768px) {
    padding: 20px 0;
    padding-top: 20px;
  }
`;

export const InfoWrapper = styled.div`
  z-index: 1;
  padding: 0 48px;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1280px;
  margin: auto;
  /* margin-bottom: 20px; */
`;

export const CertificateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1280px;
  margin: auto;
  /* margin-bottom: 20px; */

  @media screen and (max-width: 1150px) {
    flex-direction: column;
  }
`;

export const Column1 = styled.div`
  flex: 1;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const Column2 = styled.div`
  flex: 1;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 25px;
  width: 100%;
`;

export const TextWrapper = styled.div`
  padding-top: 0;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextWrapperColumn = styled.div`
  padding-top: 0;
  display: flex;
  flex-direction: column;
`;

export const TopLine = styled.p`
  color: #01bf71;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600px;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  margin: auto;
`;

export const Img = styled.img`
  width: 100%;
  height: 250px;
  margin: 20px 0 10px 0;
  padding-right: 0;
`;

export const CertificateLicenseRow = styled.div`
  padding-top: 0;
  width: 100%;
  margin: auto;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CertificateLicense = styled.div`
  width: 500px;
  color: black;
  margin: auto;
  margin-top: 20px;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const CertificateLicenseImg = styled.img`
  width: 100%;
  height: 450px;
  padding-right: 0;
  display: block;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 8px 18px;

  &:hover {
    transform: scale(1.01);
  }

  @media screen and (max-width: 650px) {
    height: auto;
  }
`;

export const Contractor = styled.img`
  width: 500px;
  height: 100px;
  padding-right: 0;
  display: block;
  margin: auto;
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 8px 18px;

  &:hover {
    transform: scale(1.01);
  }

  @media screen and (max-width: 650px) {
    width: 100%;
    height: auto;
  }
`;

export const AboutSubItem = styled.div`
  width: 100%;
  color: black;
`;

export const AboutSubItemContent = styled.div`
  width: 100%;
  color: black;
  margin-bottom: 20px;
  text-align: justify;
  line-height: 25px;
`;

export const AboutSubHeading = styled.h2`
  color: black;
  margin-bottom: 15px;
  margin-top: 20px;
  width: 100%;
  display: block;
`;
