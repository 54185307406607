import React from 'react';

import './footer.css';
import { NavLinks } from '../Navbar/NavbarElements';

const circle = () => {
  return (
    <p
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        borderColor: 'gray',
        backgroundColor: 'gray',
        display: 'inline-block',
        marginRight: '10px',
      }}
    ></p>
  );
};

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        All right reserved. copyright &copy; {new Date().getFullYear()}
      </div>

      <div class="footer-info">
        <div>
          <div>
            <NavLinks to="services">{circle()}Services</NavLinks>
            <div className="mu">
              <NavLinks to="clients">{circle()}Clients</NavLinks>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div
              style={{
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                padding: '0 1rem',
                height: ' 100%',
              }}
            >
              {circle()}01711643271
            </div>
            <div className="mu">
              <div
                style={{
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  padding: '0 1rem',
                  height: ' 100%',
                }}
              >
                {circle()}asian_pwr@yahoo.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <Box>
    //   <Container>
    //     <Row>
    //       <Column>
    //         <Heading>About Us</Heading>
    //         <FooterLink href="#">Mission</FooterLink>
    //         <FooterLink href="#">Vision</FooterLink>
    //         <FooterLink href="#">About</FooterLink>
    //       </Column>
    //       <Column>
    //         <Heading>Services</Heading>
    //         <FooterLink href="#">Electrical</FooterLink>
    //         <FooterLink href="#">Mechanical</FooterLink>
    //         <FooterLink href="#">Consultancy</FooterLink>
    //         <FooterLink href="#">Installation</FooterLink>
    //       </Column>
    //       <Column>
    //         <Heading>Contact Us</Heading>
    //         <FooterLink href="about">Dhaka</FooterLink>
    //         <FooterLink href="#">Savar</FooterLink>
    //         <FooterLink href="#">Chittagong</FooterLink>
    //         <FooterLink href="#">Khulna</FooterLink>
    //       </Column>
    //       <Column>
    //         <Heading>Social Media</Heading>
    //         <FooterLink href="#">
    //           <i className="fab fa-facebook-f">
    //             <span>Facebook</span>
    //           </i>
    //         </FooterLink>
    //         <FooterLink href="#">
    //           <i className="fab fa-instagram">
    //             <span>Instagram</span>
    //           </i>
    //         </FooterLink>
    //         <FooterLink href="#">
    //           <i className="fab fa-twitter">
    //             <span>Twitter</span>
    //           </i>
    //         </FooterLink>
    //         <FooterLink href="#">
    //           <i className="fab fa-youtube">
    //             <span>Youtube</span>
    //           </i>
    //         </FooterLink>
    //       </Column>
    //     </Row>
    //   </Container>
    // </Box>
  );
};
export default Footer;
