import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";
import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 80vh;
  position: relative;
  /* z-index: 2; */

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent);
    z-index: 2;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
  background: #232a34;
`;

export const HeroContent = styled.div`
  max-width: 1280px;
  margin: auto;
  z-index: 3;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 3rem;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const HeroP = styled.p`
  margin-top: 1.5rem;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
  align-items: center;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
  align-items: center;
`;

// export const HeroContainer = styled.div`
//   background: #0c0c0c;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 0 30px;
//   height: 80vh;
//   position: relative;
//   z-index: 2;

//   :before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: linear-gradient(
//         180deg,
//         rgba(0, 0, 0, 0.2) 0%,
//         rgba(0, 0, 0, 0.6) 100%
//       ),
//       linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent);
//     z-index: 2;
//   }
// `;

// export const HeroBg = styled.div`
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
// `;

// export const VideoBg = styled.video`
//   width: 100%;
//   height: 80vh;
//   -o-object-fit: cover;
//   object-fit: cover;
//   background: #232a34;
// `;

// export const HeroContent = styled.div`
//   max-width: 1280px;
//   margin: auto;
//   z-index: 3;
//   position: absolute;
//   padding: 8px 24px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// export const HeroH1 = styled.h1`
//   color: #fff;
//   font-size: 3rem;
//   text-align: center;

//   @media screen and (max-width: 768px) {
//     font-size: 2.5rem;
//   }
//   @media screen and (max-width: 480px) {
//     font-size: 2rem;
//   }
// `;

// export const HeroP = styled.p`
//   margin-top: 1.5rem;
//   color: #fff;
//   font-size: 1rem;
//   text-align: center;
//   max-width: 600px;

//   @media screen and (max-width: 768px) {
//     font-size: 2rem;
//   }
//   @media screen and (max-width: 480px) {
//     font-size: 1rem;
//   }
// `;

// export const HeroBtnWrapper = styled.div`
//   margin-top: 2rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// export const ArrowForward = styled(MdArrowForward)`
//   margin-left: 8px;
//   font-size: 20px;
//   align-items: center;
// `;
// export const ArrowRight = styled(MdKeyboardArrowRight)`
//   margin-left: 8px;
//   font-size: 20px;
//   align-items: center;
// `;
