import React, { useState } from 'react';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MdCastForEducation } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import { GrUserManager } from 'react-icons/gr';
import { FaMobileAlt } from 'react-icons/fa';
import Alert from '@mui/material/Alert';

import {
  ContactContainer,
  ContactForm,
  ContactHeader,
  ContactInfo,
  ContactWrapper,
} from './ContactElement';

import './style.css';

const Contact = () => {
  const ref = useRef(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const contactPersonCard = (name, educations, designations, cells, emails) => {
    return (
      <Card
        sx={{
          minWidth: 275,
          textAlign: 'justify',
          cursor: 'default',
          '&:hover': {
            background: '#eaeaea',
          },
        }}
        className="contact-person"
      >
        {/* <CardActionArea style={{ cursor: 'default' }}> */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>

          {educations.map((edu) => (
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
              key={edu}
              variant="body2"
              color="text.secondary"
            >
              <MdCastForEducation
                style={{
                  color: 'black',
                  width: '15px',
                  height: '20px',
                }}
              />
              <span style={{ marginLeft: '10px' }}>{edu}</span>
            </Typography>
          ))}

          {designations.map((designation) => (
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
              key={designation}
              variant="body2"
              color="text.secondary"
            >
              <GrUserManager
                style={{
                  color: 'black',
                  width: '15px',
                  height: '20px',
                }}
              />
              <span style={{ marginLeft: '10px' }}>{designation}</span>
            </Typography>
          ))}

          {cells.map((cell) => (
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
              key={cell}
              variant="body2"
              color="text.secondary"
            >
              <FaMobileAlt
                style={{
                  color: 'black',
                  width: '15px',
                  height: '16px',
                }}
              />
              <span style={{ marginLeft: '10px' }}>{cell}</span>
            </Typography>
          ))}

          {emails.map((email, ind) => (
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
              key={email}
              variant="body2"
              color="text.secondary"
            >
              <AiOutlineMail
                style={{
                  color: 'black',
                  width: '15px',
                  height: '16px',
                }}
              />
              <span style={{ marginLeft: '10px' }}>{email}</span>
            </Typography>
          ))}
        </CardContent>
        {/* </CardActionArea> */}
      </Card>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        ref.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        () => {
          ref.current = null;
          setName('');
          setEmail('');
          setMsg('');
          setSubmitted(true);
        },
        () => {}
      );
  };

  return (
    <ContactContainer id="contact">
      <ContactHeader>Contact</ContactHeader>
      <ContactWrapper>
        <ContactInfo>
          {contactPersonCard(
            'Mr. Emdadul Haque',
            ['B.Sc. Engr. (EEE)'],
            ['Managing Director'],
            ['01711643271'],
            ['asian_pwr@yahoo.com', 'asianpower26@gmail.com']
          )}

          <br />

          {contactPersonCard(
            'Farzana Haque',
            ['B.A.'],
            ['Manager (Commercial)'],
            ['01823-070480'],
            ['asian_pwr@yahoo.com']
          )}
        </ContactInfo>

        <ContactForm>
          <form ref={ref} onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your name.."
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your email.."
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <label htmlFor="msg">Message</label>
            <textarea
              name="msg"
              id="msg"
              cols="80"
              rows="10"
              required
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
            ></textarea>

            <input type="submit" value="Submit" />

            {submitted && (
              <Alert severity="success" color="success">
                Your message sent!
              </Alert>
            )}
          </form>
        </ContactForm>
      </ContactWrapper>
    </ContactContainer>
  );
};

export default Contact;
