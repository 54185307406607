import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";

import {
  Column1,
  Column2,
  Img,
  ImgWrap,
  InfoContainer,
  InfoRow,
  InfoWrapper,
  TextWrapper,
  AboutSubItem,
  AboutSubHeading,
  InfoHeader,
  CertificateLicense,
  CertificateLicenseImg,
  CertificateLicenseRow,
  Contractor,
  TextWrapperColumn,
  CertificateRow,
} from "./InfoElements";

import "./info.css";

const introText =
  "Asian Power is an important as well as renowned name in Bangladesh Electrical, Mechanical & Civil Engineering sector. Asian Power works as an established Engineering Firm with strong roots in 33/11KVA Sub - Station, 33KV & 11 KV overhead line construction works, underground line construction works, electrical drawing design, electrical consultancy, electromechanical and installation works. It is a technology based organization. The company vision has always been the creation of technology platforms which propels the nation’s vision. It has grown from strength to strength since its humble beginning in early 2001. The spectacular success over two decades has been made possible by its firm commitment to high standards of technical performance. Technology development is the core in creating our competitive edge, which has proven successful with the deployment of products and solutions of global standards.";
const visionText =
  "Asian Power aspires to be a leading Electrical power solution provider at reaching the far end of the globalized world. We determined the best quality services and developed the best quality services for the valuable customer.";
const missionText =
  "To develop business with responsibility and to take responsibility for developing human kind. Asian Power provides a whole range of services to customers, which includes complete power Solution, installation, energy saving lighting, power saving electrical system and solar system provider, site preparation, installation, integration, operation & maintenance.";
const qualityAssurance =
  "Quality is the totality of characteristics of an entity that bear on its ability to satisfy stated and implied needs. Quality is governed by the requirements and specification set by the customer. In the contractual environment, needs are specified whereas in other environments implied needs should be identified and defined. In many instances need can be changed in time: this implies periodic revision of specifications. Therefore, overall project management plays a vital role in achieving desired quality. For Quality Assurance, Asian Power has standard quality control procedures. We adopt operational techniques and activities that are used to fulfill requirements for quality. The operational techniques and activities aim at both monitoring the process of execution and eliminating causes unsatisfactory performances in order to result in effectiveness. All the planned and systematic activities implemented within the quality system and demonstrated as needed, to provide adequate confidence that an entity will fulfill requirements for quality. Asian Power quality assurance system involves all phases from initial identification to final satisfaction of customer expectations, which are pertinent to the quality of a service. QA activities are oriented towards the reduction, elimination and most importantly prevention of quality deficiencies.";
const safetyPolicy =
  "We usually follow the standard safety regulation and procedures applicable to safeguard plant equipment, public and employees during installation and carry out maintenance work. We provide safety training to installation, operation and maintenance engineers. We keep standard safety accessories as safety boots, safety gloves, apron and safety clothing for maintenance and electrical works, safety belt for tower climbing, safety goggles for welding works, safety helmet etc.";

const Info = ({
  lightBg,
  id,
  imgStart,
  alt,
  img,
  certificate,
  contractor,
  contractor2,
}) => {
  const renderCard = (text, heading, height) => {
    return (
      <Card
        sx={{
          minWidth: 275,
          textAlign: "justify",
          height,
          cursor: "default",
          "&:hover": {
            background: "#eaeaea",
          },
        }}
        className="info-card"
      >
        {/* <CardActionArea style={{ cursor: 'default' }}> */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {heading}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </CardContent>
        {/* </CardActionArea> */}
      </Card>
    );
  };

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoHeader>About</InfoHeader>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <TextWrapper>
              <Column2>
                <ImgWrap>
                  <Img src={img} alt={alt} />
                </ImgWrap>
              </Column2>

              <Column1>{renderCard(introText)}</Column1>
            </TextWrapper>
          </InfoRow>

          <InfoRow imgStart={imgStart}>
            <TextWrapper>
              <Column2>
                <AboutSubItem class="vision">
                  {/* <AboutSubHeading>Vision:</AboutSubHeading> */}
                  {renderCard(visionText, "Vision")}
                </AboutSubItem>
              </Column2>

              <Column1>
                <AboutSubItem>
                  {/* <AboutSubHeading>Mission:</AboutSubHeading> */}
                  {renderCard(missionText, "Mission")}
                </AboutSubItem>
              </Column1>
            </TextWrapper>
          </InfoRow>

          <CertificateRow>
            <CertificateLicenseRow imgStart={imgStart}>
              <AboutSubHeading>Certificates (ISO):</AboutSubHeading>
              <CertificateLicense>
                <CertificateLicenseImg src={certificate} alt={alt} />
              </CertificateLicense>
            </CertificateLicenseRow>

            <CertificateLicenseRow imgStart={imgStart}>
              <AboutSubHeading>E - contractor license:</AboutSubHeading>
              <CertificateLicense>
                <CertificateLicenseImg src={contractor} alt={alt} />
                {/* <Contractor src={contractor2} alt={alt} /> */}
              </CertificateLicense>
            </CertificateLicenseRow>
          </CertificateRow>

          <InfoRow imgStart={imgStart}>
            <TextWrapperColumn>
              <Column2>
                <AboutSubItem>
                  {/* <AboutSubHeading>Safety Policy:</AboutSubHeading> */}
                  {renderCard(safetyPolicy, "Safety Policy")}

                  {/* <AboutSubItemContent>
                    We usually follow the standard safety regulation and
                    procedures applicable to safeguard plant equipment, public
                    and employees during installation and carry out maintenance
                    work. We provide safety training to installation, operation
                    and maintenance engineers. We keep standard safety
                    accessories as safety boots, safety gloves, apron and safety
                    clothing for maintenance and electrical works, safety belt
                    for tower climbing, safety goggles for welding works, safety
                    helmet etc.
                  </AboutSubItemContent> */}
                </AboutSubItem>
              </Column2>

              <Column1>
                <AboutSubItem>
                  {/* <AboutSubHeading>Quality Assurance:</AboutSubHeading> */}
                  {renderCard(qualityAssurance, "Quality Assurance")}
                  {/* <AboutSubItemContent>
                    Quality is the totality of characteristics of an entity that
                    bear on its ability to satisfy stated and implied needs.
                    Quality is governed by the requirements and specification
                    set by the customer. In the contractual environment, needs
                    are specified whereas in other environments implied needs
                    should be identified and defined. In many instances need can
                    be changed in time: this implies periodic revision of
                    specifications. Therefore, overall project management plays
                    a vital role in achieving desired quality. For Quality
                    Assurance, Asian Power has standard quality control
                    procedures. We adopt operational techniques and activities
                    that are used to fulfill requirements for quality. The
                    operational techniques and activities aim at both monitoring
                    the process of execution and eliminating causes
                    unsatisfactory performances in order to result in
                    effectiveness. All the planned and systematic activities
                    implemented within the quality system and demonstrated as
                    needed, to provide adequate confidence that an entity will
                    fulfill requirements for quality. Asian Power quality
                    assurance system involves all phases from initial
                    identification to final satisfaction of customer
                    expectations, which are pertinent to the quality of a
                    service. QA activities are oriented towards the reduction,
                    elimination and most importantly prevention of quality
                    deficiencies.
                  </AboutSubItemContent> */}
                </AboutSubItem>
              </Column1>
            </TextWrapperColumn>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Info;
