import React from 'react';
import {
  Client,
  ClientHead,
  ClientName,
  CLientWrapper,
  Container,
} from './ClientElements';

const Clients = () => {
  return (
    <>
      <Container id="clients">
        <ClientHead> Our Clients </ClientHead>

        <CLientWrapper>
          <Client>
            <ClientName>Bangladesh Power Development Board (BPDB)</ClientName>
          </Client>

          <Client>
            <ClientName>Daeyu Bangladesh Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>Union Properties Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>Polo Knit Composites Ind. Ltd. (AJI Group)</ClientName>
          </Client>

          <Client>
            <ClientName>Fu-Wang Ceramic Industry Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>Urmi Group</ClientName>
          </Client>

          <Client>
            <ClientName>SM Knitwear Industries Ltd. (SM Group)</ClientName>
          </Client>

          <Client>
            <ClientName>Meghna Bangladesh Ltd. ( Unit-2)</ClientName>
          </Client>

          <Client>
            <ClientName>PRAN-RFL Group.</ClientName>
          </Client>

          <Client>
            <ClientName>Barundi Super Auto Bricks</ClientName>
          </Client>

          <Client>
            <ClientName>Biswas builders limited</ClientName>
          </Client>

          <Client>
            <ClientName>Apex Pharma Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>
              Meghna Bangladesh Mahim Cycle (Meghna Group)
            </ClientName>
          </Client>

          <Client>
            <ClientName>Runner Automobiles Ltd</ClientName>
          </Client>

          <Client>
            <ClientName>Orient Energy Systems Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>Catharsis Medical Center</ClientName>
          </Client>

          <Client>
            <ClientName>Kims Corporation Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>Ridge Park Holdings Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>Tanin Electrical Appliances Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>Youngone Group (Korean EPZ)</ClientName>
          </Client>

          <Client>
            <ClientName>
              Star Particle Board Mills Ltd (Partex Group)
            </ClientName>
          </Client>

          <Client>
            <ClientName>Advanced Development Technologies Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>R.A.K. Ceramics (Bangladesh) Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>ACI Foods Limited.</ClientName>
          </Client>

          <Client>
            <ClientName>Chaity Composite Ltd. (Chaity Group)</ClientName>
          </Client>

          <Client>
            <ClientName>Meghna Knit Composite Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>A.R Trade International</ClientName>
          </Client>

          <Client>
            <ClientName>Korean EPZ (KEPZ) Corporation (BD) Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>Tarn Associates Ltd.</ClientName>
          </Client>

          <Client>
            <ClientName>Union Group</ClientName>
          </Client>

          <Client>
            <ClientName>
              Bangladesh Rural Electrification Board (BREB)
            </ClientName>
          </Client>

          <Client>
            <ClientName>R.S. Enterprise</ClientName>
          </Client>
        </CLientWrapper>
      </Container>
    </>
  );
};

export default Clients;
